import PageTitle from "../../../components/PageTitle";
import ContactForm from "./components/ContactForm";
import "../../styles.css";

function Contact() {
    const titre = "Contact";
    const text = (
        <div>
            <p>Une question ?</p>
            <p>Un problème ?</p>
            <p>Envie de s'investir ? </p>
            <p>Envie de partager ? </p>
            <p>Je vous laisse me contacter ici.</p>
        </div>
    );

    return (
        <div className="bg-slate-50">
            <div className="grid-container container">
                <div className="grid-item">
                    <PageTitle title={titre} text={text} />
                </div>

                <div className="grid-item flex gap-4 text-xl">
                    <ContactForm />
                </div>
            </div>
        </div>
    );
}

export default Contact;
