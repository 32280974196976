import chapters from "./data/chapters";
import StepCard from "./components/StepCard";
import "../styles.css";

function Cheminer() {
    return (
        <div className="bg-primary-light">
            <main className="grid-container container">
                {chapters.map((card, index, col, start) => (
                    <div className="grid-item" key={index}>
                        <StepCard
                            title={card.title}
                            text={card.text}
                            imagePath={card.imagePath}
                            alt={card.alt}
                            imagePosition={card.imagePosition}
                            index={index}
                        />
                    </div>
                ))}
            </main>
        </div>
    );
}

export default Cheminer;
