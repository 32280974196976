import React from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const PetalMindMap = () => {
    const center = { x: 500, y: 250 };
    const petals = 8; // nombre de pétales (petites ellipses)
    const radius = 200; // distance du centre pour les pétales
    const petalSize = { width: 80, height: 40 }; // taille des pétales

    // Liste des liens pour chaque pétale
    const petalLinks = [
        ["Lien 1.1", "Lien 1.2", "Lien 1.3"],
        ["Lien 2.1", "Lien 2.2"],
        ["Lien 3.1", "Lien 3.2", "Lien 3.3", "Lien 3.4"],
        ["Lien 4.1", "Lien 4.2"],
        ["Lien 5.1", "Lien 5.2", "Lien 5.3"],
        ["Lien 6.1"],
        ["Lien 7.1"],
        ["Lien 8.1"],
    ];

    // Fonction pour générer une position aléatoire à l'intérieur d'un pétale
    const getRandomPositionInPetal = () => {
        const x = Math.random() * petalSize.width - petalSize.width / 2;
        const y = Math.random() * petalSize.height - petalSize.height / 2;
        return { x, y };
    };

    const secondaryTitle = [
        { title: "santé communautaire", color: "pink" },
        { title: "santé intégrative", color: "indigo" },
        { title: "soigner la mort", color: "grey" },
        { title: "éthique", color: "purple" },
        { title: "soin des soignant.es", color: "yellow" },
        { title: "service public", color: "orange" },
        { title: "désobéissance civile", color: "red" },
        { title: "santé planétaire", color: "green" },
    ];

    const petalPositions = Array.from({ length: petals }).map((_, i) => {
        const angle = (i / petals) * 2 * Math.PI;
        return {
            x: center.x + radius * Math.cos(angle) - petalSize.width / 2,
            y: center.y + radius * Math.sin(angle) - petalSize.height / 2,
            title: secondaryTitle[i].title,
            color: secondaryTitle[i].color,
        };
    });

    return (
        <div className="w-full border border-black">
            <TransformWrapper>
                <TransformComponent>
                    <svg width="1000" height="500">
                        {/* Ellipse centrale */}
                        <ellipse
                            cx={center.x}
                            cy={center.y}
                            rx="100"
                            ry="50"
                            fill="lightblue"
                            stroke="black"
                            strokeWidth="2"
                        />
                        <text
                            x={center.x - 40}
                            y={center.y}
                            fontSize="14"
                            fill="black"
                            className="uppercase"
                        >
                            Faire Corps
                        </text>

                        {/* Pétales */}
                        {petalPositions.map((pos, index) => (
                            <g key={index}>
                                <ellipse
                                    cx={pos.x + petalSize.width / 2}
                                    cy={pos.y + petalSize.height / 2}
                                    rx={petalSize.width}
                                    ry={petalSize.height}
                                    fill={pos.color}
                                    stroke="black"
                                    strokeWidth="2"
                                />
                                <text
                                    x={pos.x - 10}
                                    y={pos.y + 25}
                                    fontSize="12"
                                    fill="black"
                                    className="uppercase"
                                >
                                    {pos.title}
                                </text>
                                {/* Liens dans le pétale */}
                                {petalLinks[index].map((link, linkIndex) => {
                                    const randomPosition =
                                        getRandomPositionInPetal();
                                    return (
                                        <text
                                            key={linkIndex}
                                            x={
                                                pos.x +
                                                petalSize.width / 2 +
                                                randomPosition.x
                                            }
                                            y={
                                                pos.y +
                                                petalSize.height / 2 +
                                                randomPosition.y
                                            }
                                            fontSize="10"
                                            fill="blue"
                                            cursor="pointer"
                                        >
                                            <a href="#">{link}</a>
                                        </text>
                                    );
                                })}
                            </g>
                        ))}
                    </svg>
                </TransformComponent>
            </TransformWrapper>
        </div>
    );
};

export default PetalMindMap;
