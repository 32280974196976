const partenaires = [
    {
        title: "Prendre soin de l'intéret commun",
        content: (
            <div className="flex flex-col gap-4 md:flex-row">
                <div className="md:w-2/3">
                    <p>
                        En 2019, après l'envoi de quelques poésies à tous les
                        médecins du CHU où j'exerçais, puis à la direction
                        également, j'ai rencontré Michel, Professeur de
                        gynécologie-obstétrique. J'ai rencontré ses textes, son
                        énergie et ses coups de gueule au service d'une
                        mobilisation médicale pour l'hôpital public. Puis
                        quelques semaines plus tard, le{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.collectif-inter-hopitaux.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                Collectif Inter Hopitaux (CIH)
                            </a>
                        </span>{" "}
                        est né à Paris et nous l'avons tous deux animé à
                        Clermont-Ferrand. Depuis, je reste en lien avec ce
                        collectif et tente de faire ma part pour donner de la
                        voix au service public de la santé.
                    </p>
                </div>
                <div className="flex items-center justify-center md:w-1/3">
                    <a
                        href="https://www.collectif-inter-hopitaux.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            loading="lazy"
                            className="max-h-52 object-contain md:max-h-none"
                            alt="Logo Collectif Inter Hopitaux"
                            src="https://images.squarespace-cdn.com/content/v1/5e356e21fb55d365939c3deb/1580582667700-KUY7H3H0F5FSEQ0ZRGYZ/Logo%2Bcentre%25CC%2581.jpg?format=1500whttps://images.squarespace-cdn.com/content/v1/5e356e21fb55d365939c3deb/1580582667700-KUY7H3H0F5FSEQ0ZRGYZ/Logo%2Bcentre%25CC%2581.jpg?format=1500w"
                        />
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "Coopérer n'est pas inné",
        content: (
            <div className="flex flex-col gap-4 md:flex-row-reverse">
                <div className="flex items-center md:w-2/3">
                    <p>
                        Au même moment, j'ai rencontré Claire au comité éthique
                        de l'hopital, qui exerçait comme aumonier. Elle m'a fait
                        découvrir l'association{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.soignonshumain.com"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                Soignons Humain
                            </a>
                        </span>{" "}
                        et plus encore{" "}
                        <span className="font-bold">
                            l'univers de l'intelligence collective.
                        </span>{" "}
                        Les livres de Frédéric Laloux ;{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.reinventingorganizations.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                Reinventing Organization
                            </a>
                        </span>
                        . Puis Colin m'a mis en lien avec{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://universite-du-nous.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                l'Université du Nous
                            </a>
                        </span>
                        . J'ai rencontré aussi Benoit, ancien infirmier qui
                        éditait{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.esanum.fr/blogs"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                le blog Esanum France
                            </a>
                        </span>
                        . J'y ai écrit quelques articles avec Lydwine qui
                        m'avait inspirée avec son livre{" "}
                        <span className="text-primary-bold italic">
                            <a
                                href="https://www.hospitalia.fr/AFNOR-Editions-publie-Reinventons-le-secteur-de-la-sante--une-formidable-synthese-des-initiatives-manageriales-les-plus_a2041.html"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                Réinventons le secteur de la santé ; ils l'ont
                                fait, découvrez leurs clés !
                            </a>
                        </span>
                    </p>
                </div>
                <div className="flex items-center justify-center md:w-1/3">
                    <a
                        href="https://www.hospitalia.fr/AFNOR-Editions-publie-Reinventons-le-secteur-de-la-sante--une-formidable-synthese-des-initiatives-manageriales-les-plus_a2041.html"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                    >
                        <img
                            loading="lazy"
                            className="max-h-52 object-contain md:max-h-none"
                            alt="Réinventons le secteur de la santé ; ils l'ont fait,
                            découvrez leurs clés !"
                            src="https://www.hospitalia.fr/photo/art/default/41482627-34852351.jpg?v=1578399021"
                        />
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "Faire communauté en santé",
        content: (
            <div className="flex flex-col gap-4">
                <p>
                    Quelques temps plus tard, j'ai découvert cette vidéo :{" "}
                    <span className="italic">Un autre soin est possible.</span>{" "}
                    Grace à qui ? Je ne sais meme plus hélas. Cette histoire m'a
                    saisie, m'a éveillée, m'a réanimée.{" "}
                    <span className="font-bold">
                        Un centre de santé communautaire
                    </span>{" "}
                    à Eschirolles. L'histoire de sa création contée par une
                    étudiante en sociologie, Amel, et un interne en médecine,
                    Benoît, que je rencontrerais plus tard.
                </p>
                <div
                    className="relative w-full"
                    style={{ paddingBottom: "56.25%" }}
                >
                    <iframe
                        className="absolute left-0 top-0 h-full w-full"
                        src="https://www.youtube.com/embed/TPn_edifie0?si=60tdpbvcqiNjRIe6"
                        title="YouTube video player"
                        allow="web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        ),
    },
    {
        title: "Sublimer à plusieurs",
        content: (
            <div className="flex flex-col gap-4">
                <div className="flex items-center">
                    <p>
                        La crise du COVID et la crainte de pénurie de
                        respirateur m'ont permis de rencontré Jean et{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.hermitagelelab.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                l'Hermitage
                            </a>
                        </span>
                        . Une histoire de famille : Maud, sa soeur, avec qui
                        j'avais travaillé et milité m'avait mise en contact avec
                        lui. Me voici fraichement débarquée dans l'univers des
                        Tiers Lieux puis dans celle des makers et de la low tech
                        avec Antoine et ses acolytes du{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://leclubsandwich.studio/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                Club Sandwich
                            </a>
                        </span>
                        .
                    </p>
                </div>
                <div className="flex items-center justify-center">
                    <a
                        href="https://www.hermitagelelab.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            loading="lazy"
                            className="max-h-52 object-contain md:max-h-none"
                            title="jardins en scene - Crédits - Hadrien Meyer"
                            alt="jardins en scene - Crédits - Hadrien Meyer"
                            src="https://www.hermitagelelab.com/wp-content/uploads/2023/09/jardins-en-scene-Credits-Hadrien-Meyer-scaled-e1693916542240-1280x720.jpg"
                        />
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "En choeur et en corps",
        content: (
            <div className="flex flex-col gap-4">
                <p>
                    Synchronicité avec la rencontre de Cloé. Nous voici lancées
                    dans la réalisation des documentaires pédagogiques
                    "Méditation et médecine en 2021 : éloge du care ?" ainsi que
                    dans la création de son MOOC associé, sorte de parcours
                    pédagogique en ligne.
                </p>
                <br />
                <p className="underline">
                    Lien des 5 épisodes documentaires - Méditation et médecine :
                    éloge du Care ? :
                </p>
                <p>
                    <a
                        href="https://youtu.be/iSM8cuI2hok?feature=shared"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                    >
                        Episode 1 - La méditation de pleine conscience et le
                        corps sensible
                    </a>
                </p>
                <p>
                    <a
                        href="https://youtu.be/T9GLhsurGLc?feature=shared"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                    >
                        Episode 2 - Un soignant est un être humain
                    </a>
                </p>
                <p>
                    <a
                        href="https://youtu.be/61oqObobpWI?feature=shared"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                    >
                        Episode 3 - La méditation de pleine conscience et la
                        recherche interdisciplinaire
                    </a>
                </p>
                <p>
                    <a
                        href="https://youtu.be/4vkGs2NqnW4?feature=shared"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                    >
                        Episode 4 - La culture du care "prendre soin"
                    </a>
                </p>
                <p>
                    <a
                        href="https://youtu.be/xPrnUG6GCH4?feature=shared"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                    >
                        Episode 5 - Prendre soin de soi, de l'autre et du monde
                    </a>
                </p>
                <div
                    className="relative w-full"
                    style={{ paddingBottom: "56.25%" }}
                >
                    <iframe
                        className="absolute left-0 top-0 h-full w-full"
                        src="https://www.youtube.com/embed/videoseries?si=Wm5cZB8IBpPLbwuH&amp;list=PLRHVM3XoG5iFt9j8zgTzXXKnxlBodUhoG"
                        title="YouTube video player"
                        allow="web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        ),
    },
    {
        title: "Parler de la mort",
        content: (
            <div className="flex flex-col gap-4 md:flex-row">
                <div className="flex items-center md:w-2/3">
                    <p>
                        Le sujet de la mort et de la fin de vie était toujours
                        présent en moi, au travers notamment de la question des
                        directives anticipées, volontés, exprimées par écrit,
                        sur les décisions médicales à prendre lorsque nous ne
                        sommes plus en état de les communiquer. J'ai pu animer{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.lamontagne.fr/clermont-ferrand-63000/actualites/je-prepare-ma-mort-pour-mieux-vivre-des-ateliers-pour-rediger-ses-directives-anticipees-a-clermont-ferrand_13889195/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                des ateliers citoyens
                            </a>
                        </span>{" "}
                        et continuer{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.plateforme-recherche-findevie.fr/projets/soigner-la-mort"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                le travail de recherche
                            </a>
                        </span>{" "}
                        sur cette thématique en croisant la route d'Emma.
                    </p>
                </div>
                <div className="flex items-center justify-center md:w-1/3">
                    <a
                        href="https://www.plateforme-recherche-findevie.fr/projets/soigner-la-mort"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                    >
                        <img
                            loading="lazy"
                            className="h-auto max-h-52 w-full object-contain invert md:max-h-none"
                            alt="Plateforme nationale pour la recherche sur la fin de vie"
                            src="https://www.plateforme-recherche-findevie.fr/sites/default/files/003.png"
                        />
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "Donner de la voix à ce qui émerge",
        content: (
            <div className="flex flex-col gap-4">
                <div className="flex items-center">
                    <p>
                        Yann m'a motivée et ravie en lançant{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.fabsan.cc/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                la Fabrique des santés
                            </a>
                        </span>
                        . La Fabrique des santés est un collectif qui facilite
                        les coopérations ouvertes et l'émergence de communs dans
                        le domaine de la santé et du soin. La fabrique organise
                        et partage des wébinaires inspirants pour découvrir et
                        prendre soin des{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.fabsan.cc/faire-tiers-lieux-en-sante"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                alternatives en santé
                            </a>
                        </span>
                        .
                    </p>
                </div>
                <div className="flex items-center justify-center">
                    <a
                        href="https://www.fabsan.cc/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                    >
                        <img
                            loading="lazy"
                            className="h-auto w-full object-contain"
                            alt="La Fabrique des santés"
                            src="https://custom-images.strikinglycdn.com/res/hrscywv4p/image/upload/c_limit,fl_lossy,h_9000,w_1200,f_auto,q_auto/3323786/507252_268771.png"
                        />
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "Préserver l'habitabilité de la planète",
        content: (
            <div className="flex flex-col gap-4 md:flex-row-reverse">
                <div className="flex items-center md:w-2/3">
                    <p>
                        Mélanie m'a émerveillée en m'ouvrant la porte et la
                        connaissance de{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://santeplanetaire.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                l'Alliance Santé Planétaire dite ASP
                            </a>
                        </span>
                        , un collectif que j'affectionne tout particulièrement
                        et auquel je contribue. L'ASP promeut la santé
                        planétaire, une approche globale de la notion de santé,
                        reliant différentes disciplines scientifiques (biologie,
                        écologie, climatologie, anthropologie, etc.) et
                        pratiques (médecines humaine et vétérinaire,
                        architecture, agronomie, etc.) pour contribuer à l’élan
                        commun visant à permettre à chacun·e d’agir. Agir pour
                        créer des solutions soutenables pour vivre dans un monde
                        sain, habitable et socialement juste.
                    </p>
                </div>
                <div className="flex items-center justify-center md:w-1/3">
                    <a
                        href="https://santeplanetaire.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                    >
                        <img
                            loading="lazy"
                            className="h-auto max-h-32 w-full object-contain md:max-h-none"
                            alt="Alliance Santé Planétaire"
                            src="https://santeplanetaire.org/wp-content/uploads/2023/06/asp-illustration-2.png"
                        />
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "Transmettre",
        content: (
            <div className="flex flex-col gap-4 md:flex-row">
                <div className="flex items-center md:w-2/3">
                    <p>
                        Transmettre avec Cloé au sein de{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://www.mumedecine.org/"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                Mu médecine
                            </a>
                        </span>
                        , une école française interdisciplinaire de santé dédiée
                        à la formation des professionnel·les des métiers du soin
                        et à l'accompagnement des lieux de soin pour une mu(e)
                        écologique et durable de la santé. Au sein d'une unité
                        optionnelle de méditation à l'UCA - Université Clermont
                        Auvergne. Ou encore au sein du{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://offre-de-formations.univ-lyon1.fr/InsertPro/20240628_1815_Plaquette%20DU%20Sante%20planetaire.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                DU Santé Planétaire
                            </a>
                        </span>{" "}
                        - Santé Durable au sein de l'université Claude Bernard à
                        Lyon.
                    </p>
                </div>
                <div className="flex items-center justify-center md:w-1/3">
                    <a
                        href="https://www.mumedecine.org/"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                    >
                        <img
                            loading="lazy"
                            className="h-auto max-h-52 w-full rounded-md object-contain md:max-h-none"
                            alt="Mu Médecine"
                            src="https://static.wixstatic.com/media/1dd2bf_df5c05997cd94e2bbbc77e61cac8c910~mv2.jpeg/v1/fill/w_2518,h_1276,al_c,q_90,usm_0.66_1.00_0.01,enc_auto/1dd2bf_df5c05997cd94e2bbbc77e61cac8c910~mv2.jpeg"
                        />
                    </a>
                </div>
            </div>
        ),
    },
    {
        title: "Apprendre à écouter",
        content: (
            <div className="flex flex-col gap-4 md:flex-row-reverse">
                <div className="flex items-center md:w-2/3">
                    <p>
                        Me former à l'écoute, à me mettre au service du
                        dialogue, au sein du collectif{" "}
                        <span className="text-primary-bold">
                            <a
                                href="https://restaurerlelien.fr"
                                target="_blank"
                                rel="noopener noreferrer"
                                className="transform transition-transform duration-300 ease-in-out hover:bg-slate-200"
                            >
                                Restaurer le lien
                            </a>
                        </span>{" "}
                        qui pratique et transmet l'approche restaurative, telle
                        qu'employée en justice restaurative.{" "}
                    </p>
                </div>
                <div className="flex items-center justify-center md:w-1/3">
                    <a
                        href="https://restaurerlelien.fr"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block"
                    >
                        <img
                            loading="lazy"
                            className="h-auto max-h-52 w-full rounded-md object-contain md:max-h-64"
                            alt="Mu Médecine"
                            src="https://i0.wp.com/restaurerlelien.fr/wp-content/uploads/2023/10/cropped-RLL1-fotor-bg-remover-20231025141910.png?w=359&ssl=1"
                        />
                    </a>
                </div>
            </div>
        ),
    },
];

export default partenaires;
