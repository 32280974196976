import {
    createBrowserRouter,
    createRoutesFromElements,
    Route,
} from "react-router-dom";
import Cheminer from "../pages/Cheminer";
import Douter from "../pages/Douter";
import Renoncer from "../pages/Renoncer";
import FaireCorps from "../pages/FaireCorps";
import Tisser from "../pages/Tisser";
import { Contact, MentionsLegales } from "../pages";
import NotFound from "../pages/NotFound";
import Root from "./Root.js";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Root />}>
            <Route path="/" element={<Cheminer />} />
            <Route path="/douter" element={<Douter />} />
            <Route path="/renoncer" element={<Renoncer />} />
            <Route path="/faire-corps" element={<FaireCorps />} />
            <Route path="/tisser" element={<Tisser />} />
            <Route path="/contact" element={<Contact />} />
            {/* <Route
                path="/conditions-generales"
                element={<ConditionsGenerales />}
            /> */}
            <Route path="/mentions-legales" element={<MentionsLegales />} />
            <Route path="*" element={<NotFound />} />
        </Route>,
    ),
);

export default router;
