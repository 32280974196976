import React, { useState } from "react";

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            // Envoi des données à ton script PHP via une requête POST avec fetch
            const response = await fetch(
                "https://monblancdetravail.fr/send-email.php",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded",
                    },
                    body: new URLSearchParams(formData),
                },
            );

            if (response.ok) {
                setSuccessMessage("Message envoyé avec succès !");
            } else {
                setErrorMessage("Erreur lors de l'envoi du message.");
            }
        } catch (error) {
            console.error("Erreur lors de l'envoi du message", error);
            setErrorMessage("Erreur lors de l'envoi du message.");
        }
    };

    if (successMessage !== "") {
        return (
            <div className="col-span-4 w-full pt-12 text-center text-2xl text-green-500 sm:col-span-8 md:col-start-3 md:text-3xl">
                {successMessage}
            </div>
        );
    }

    if (errorMessage !== "") {
        return (
            <div className="col-span-4 w-full pt-12 text-center text-2xl text-red-500 sm:col-span-8 md:col-start-3 md:text-3xl">
                {errorMessage}
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit} className="w-full">
            <div className="grid gap-4">
                <div className="col-span-4 sm:col-span-8 md:col-span-6">
                    <input
                        type="text"
                        id="name"
                        name="name"
                        placeholder="Prénom Nom"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="w-full"
                    />
                </div>
                <div className="col-span-4 sm:col-span-8 md:col-span-6">
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Adresse email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="w-full"
                    />
                </div>
                <div className="col-span-4 sm:col-span-8 md:col-span-12">
                    <textarea
                        id="message"
                        name="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                        className="h-24 w-full"
                    />
                </div>
                <div className="col-span-4 flex justify-center sm:col-span-8 md:col-span-12">
                    <button
                        type="submit"
                        className="hover:text-primary-bold transform rounded-md bg-slate-200 p-2 px-4 text-center text-2xl transition-transform duration-300 ease-in-out hover:bg-slate-200 hover:bg-slate-300"
                    >
                        Envoyer
                    </button>
                </div>
            </div>
        </form>
    );
};

export default ContactForm;
