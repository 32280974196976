const linkDatas = [
    { name: "Mention Légales", link: "/mentions-legales", col: 1, row: 1 },
    {
        name: "Conditions générales d'utilisation",
        link: "/conditions-generales",
        col: 1,
        row: 2,
    },
    {
        name: "Politique de confidentialité",
        link: "/confidentialite",
        col: 1,
        row: 3,
    },
    { name: "Gestion des cookies", link: "/cookies", col: 1, row: 4 },
    { name: "Me contacter", link: "/contact", col: 2, row: 1 },
    { name: "Partenaires", link: "/partenaires", col: 2, row: 2 },
];

export default linkDatas;
