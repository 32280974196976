import PageTitle from "../../components/PageTitle";
import partenaires from "./data/partenaires";
import LinkCard from "./components/LinkCard";

function FaireCorps() {
    const titre = "Faire Corps";
    const text = (
        <div>
            <p>Et si tout n'était que prétexte à la rencontre. </p>
            <br />
            <p>
                Quelques unes des personnes, projets, collectifs qui m'ont
                accueillie, fait grandir, soutenue.
            </p>
            <br />
            <p> Qui m'ont réanimée et continuent de m'animer. </p>
        </div>
    );

    return (
        <div className="bg-primary-light m-auto w-full py-8">
            <main className="container grid grid-cols-4 gap-4 sm:grid-cols-8 sm:gap-6 md:grid-cols-12">
                <div className="col-span-4 sm:col-span-8 md:col-span-12">
                    <PageTitle title={titre} text={text} />
                </div>
                <div className="col-span-4 flex flex-col gap-4 sm:col-span-8 md:col-span-12">
                    {partenaires.map((card, index) => (
                        <LinkCard
                            title={card.title}
                            content={card.content}
                            key={index}
                        />
                    ))}
                </div>
            </main>
        </div>
    );
}

export default FaireCorps;
