import "../../styles.css";

function MentionsLegales() {
    return (
        <div className="bg-primary-light">
            <div className="grid-container container">
                <div className="grid-item">
                    <h1 className="font-hand text-center text-5xl">
                        Mentions légales
                    </h1>
                    <p className="text-center">Version du 10 septembre 2024</p>
                    <h3 className="font-text py-4 text-2xl">
                        Édition du site Internet
                    </h3>
                    <p>
                        Le site web de Margot SMIRDEC (ci-après « le Site »),
                        accessible à l’adresse https://monblancdetravail.fr est
                        édité par Bogdan SMIRDEC (ci-après « l’Editeur »), jeune
                        fou se croyant développeur web.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Hébergeur du site Internet
                    </h3>
                    <p>
                        Le Site est hébergé par o2switch, SAS au capital de 100
                        000 €, dont le siège social est domicilié au Chemin des
                        Pardiaux, 63000 Clermont-Ferrand – France. N° Siret :
                        510 909 807 00024 L’hébergeur peut être contacté au
                        numéro de téléphone suivant : +33 4 44 44 60 40.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Respect de la propriété intellectuelle
                    </h3>
                    <p>
                        Toutes les marques, photographies, textes, commentaires,
                        illustrations, images animées ou non, séquences vidéo,
                        sons, ainsi que toutes les applications informatiques
                        qui pourraient être utilisées pour faire fonctionner le
                        Site et plus généralement tous les éléments reproduits
                        ou utilisés sur le Site{" "}
                        <span className="font-bold text-red-600">devront</span>{" "}
                        être protégés par les lois en vigueur au titre de la
                        propriété intellectuelle.
                    </p>
                    <br />
                    <p>
                        Ils sont la propriété pleine et entière de l’Editeur ou
                        de ses partenaires, sauf mentions particulières. Toute
                        reproduction, représentation, utilisation ou adaptation,
                        sous quelque forme que ce soit, de tout ou partie de ces
                        éléments, y compris les applications informatiques, sans
                        l’accord préalable et écrit de l’Editeur, sont
                        strictement interdites.
                    </p>
                    <br />
                    <p>
                        Le fait pour l’Editeur de ne pas engager de procédure
                        dès la prise de connaissance de ces utilisations non
                        autorisées ne vaut pas acceptation desdites utilisations
                        et renonciation aux poursuites.
                    </p>
                    <br />
                    <p>
                        Seule l’utilisation pour un usage privé dans un cercle
                        de famille est autorisée et toute autre utilisation est
                        constitutive de contrefaçon et/ou d’atteinte aux droits
                        voisins, sanctionnées par Code de la propriété
                        intellectuelle.
                    </p>
                    <br />
                    <p>
                        La reprise de tout ou partie de ce contenu nécessite
                        l’autorisation préalable de l’Editeur ou du titulaire
                        des droits sur ce contenu.
                    </p>
                    <h3 className="font-text py-4 text-2xl">
                        Liens hypertextes
                    </h3>
                    <p>
                        Le Site peut contenir des liens hypertexte donnant accès
                        à d’autres sites web édités et gérés par des tiers et
                        non par l’Editeur. L’Editeur ne pourra être tenu
                        responsable directement ou indirectement dans le cas où
                        lesdits sites tiers ne respecteraient pas les
                        dispositions légales.
                    </p>
                </div>
            </div>
        </div>
    );
}

export default MentionsLegales;
