import PageTitle from "../../components/PageTitle";
import PetalMindMap from "./components/PetalMindMap";
import tisser from "../../assets/images/cheminer/large/5_tisser.webp";
import "../styles.css";

function Tisser() {
    const titre = "Tisser";
    const text = (
        <div>
            <p>
                <span className="font-bold">
                    Une carte partielle, partiale, qui donne à voir des
                    émergences en santé.
                </span>
                <br />
                pour essayer de trouver à qui se lier et/ou s'inspirer pour
                oser.
            </p>
            <br />
            <p className="italic">
                "La carte n'est pas le territoire, mais elle aide à se repérer."
            </p>
            <br />
            <p>
                Des collectifs qui prennent soin de l'existant en tentant de
                sauver ce qui peut encore être sauvé.
            </p>
            <br />
            <p>
                Santé intégrative, planétaire, communautaire, quel que soit le
                nom: <br /> des personnes, lieux, associations, collectifs,
                médias, qui esquissent un visage de la santé en recomposition.
            </p>
            <br />
            <p> Transformation politique, philosophique, poétique.</p>
            <br />
            <p>Une santé qui sort des sentiers battus pour nous réanimer.</p>
        </div>
    );

    return (
        <div className="bg-slate-50 py-8">
            <div className="grid-container container">
                <div className="grid-item">
                    <PageTitle title={titre} text={text} />
                </div>
                <div className="grid-item">
                    <h1 className="bg-yellow-400 text-center text-3xl">
                        ! PAGE ACTUELLEMENT EN CONSTRUCTION !
                    </h1>
                </div>
                <div className="grid-item">
                    <img
                        src={tisser}
                        alt="MindMap"
                        className="border border-black"
                        loading="lazy"
                    />
                </div>
                <div className="grid-item">
                    <iframe
                        title="mindmap"
                        width="768"
                        height="432"
                        src="https://miro.com/app/live-embed/uXjVLcY2cck=/?moveToViewport=-1116,-971,2161,2059&embedId=277391098431"
                        frameBorder="0"
                        scrolling="no"
                        allow="fullscreen; clipboard-read; clipboard-write"
                        allowFullScreen
                    ></iframe>
                </div>
                <div className="grid-item w-full">
                    <PetalMindMap />
                </div>
            </div>
        </div>
    );
}

export default Tisser;
